import React, { useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import Link from 'next/link';
import { Icon, Title, Button } from '@local';

export const SErrorScreenWrapper = styled.div`
  ${({ theme: { grid, color, breakpoints } }) => css`
    display: flex;
    flex-direction: column;
    height: 100vh;

    p {
      color: ${color.blue};
      max-width: 618px;
      flex-grow: 1;
      margin: ${grid.vertical * 4}px 0;
      font-size: 24px;
      line-height: 1.33333em;
    }

    svg {
      margin-top: ${grid.horizontal * 6}px;
      margin-bottom: ${grid.horizontal}px;
      height: ${grid.horizontal * 5}px;
      width: ${grid.horizontal * 6}px;
    }

    button {
      margin-bottom: ${grid.vertical * 8}px;
    }

    @media (min-width: ${breakpoints.tabletMin}) {
      svg {
        height: ${grid.horizontal * 7.5}px;
        width: 170px;
        margin-top: ${grid.horizontal * 9.5}px;
        margin-bottom: ${grid.horizontal * 4.5}px;
      }
    }
  `}
`;

const Error404 = () => {
  const [titleText, setTitleText] = useState("");
  const [paragraphText, setParagraphText] = useState("");
  const [buttonText, setButtonText] = useState("");

  useEffect(() => {
    if (typeof window !== "undefined") {
      console.log(window.location.href)
      if (window.location.href.includes(".is") || window.location.href.includes("umsokn")) {
        setTitleText("Þessi síða fannst ekki");
        setParagraphText("En ekki hafa áhyggjur, þú getur farið til baka með því að nota þennan hentuga takka!");
        setButtonText("Til baka í umsókn");
      }
      else {
        setTitleText("This Page Could Not Be Found");
        setParagraphText("But don't you worry about a thing, you can go back by using this handy button!");
        setButtonText("Return to application");
      }
    }
  }, [])

  return (
    <div className="row">
      <div className="col-sm-5 col-md-5 col-lg-7 offset-md-1 offset-lg-4">
        <SErrorScreenWrapper>
          <Icon icon="VALITORICONBOX" />
            <Title className="size-md-large">
              {titleText}
            </Title>
            <p>
              {paragraphText}
            </p>
            <div className="row">
              <div className="col-lsm-5 col-md-3 col-lg-4">
                <Link href="/">
                  <Button>{buttonText}</Button>
                </Link>
              </div>
            </div>
        </SErrorScreenWrapper>
      </div>
    </div>
  )
}

export default Error404;